<template>
<div class="weather-time">

  <div class="field ">
    <div class="form">
      <div class="field">
        <v-switch v-model="landing.advanced.global.show_weather"
                  label="Enable/Disable Weather">
        </v-switch>
      </div>
      <div class="field" v-if="landing.advanced.global.show_weather" style="margin-bottom: 30px;">
        <label class="label" style="display: block;margin-bottom: 5px;">Choose your town for show weather</label>
        <v-autocomplete
            dense
            flat
            outlined
            solo
            class="input input_dense"
            placeholder="Write your town"
            :items="cities"
            item-value="id"
            item-text="name"
            @update:search-input="setCities"
            no-data-text="Write 3 or more letters"
            hide-details
            style="max-width:200px;"
            @change="setChanges()"
            v-model="landing.advanced.global.city_id"
            return-object
        ></v-autocomplete>
      </div>
      <div class="field" v-if="landing.advanced.global.show_weather && landing.advanced.global.weather.style !== 'lite'" style="margin-bottom: 30px;">
            <label class="label" style="display: block;margin-bottom:5px;">Choose your location</label>
            <div style="margin-bottom:5px;">You can write the name of the place to display in the widget</div>
            <v-text-field
                    dense
                    flat
                    outlined
                    class="input input_dense"
                    placeholder="Write your town"
                    no-data-text="Write 3 or more letters"
                    hide-details
                    style="max-width:200px;"
                    v-model="location"
                    @change="changeLandingLocation"
                    @input="changeLocation"
            ></v-text-field>
        </div>
      <div class="field" v-if="landing.advanced.global.show_weather">
        <v-switch v-model="landing.advanced.global.show_time" label="Show Time">
        </v-switch>
      </div>
      <div class="field" v-if="landing.advanced.global.show_time && landing.advanced.global.show_weather">
        <label class="label" style="display: block;margin-bottom:5px;">Choose your Timezone</label>
        <v-select
            style="width: 200px;"
            dense
            outlined
            flat
            placeholder="Timezone"
            :items="timezones"
            item-value="name"
            item-text="offset"
            v-model="landing.advanced.global.timezone"
            return-object
        >
          <template slot="selection" slot-scope="{ item }">
            {{ item.offset }}
          </template>
          <template slot="item" slot-scope="{ item }">
            {{ item.offset }}
          </template>
        </v-select>
      </div>
      <div class="field" v-if="landing.advanced.global.show_weather">
        <v-switch v-model="landing.advanced.global.show_widget"
                  label="Enable/Disable Widget">
        </v-switch>
      </div>
      <div class="field" v-if="landing.advanced.global.show_weather&&landing.advanced.global.show_widget">
        <label class="label">
          Styles
        </label>

        <v-radio-group row v-model="landing.advanced.global.weather.style">
            <v-radio
                style="margin-right: 15px;"
                v-for="type in stylesType"
                :label="type.label"
                :value="type.value"
                :key="type.id"
            >
            </v-radio>
        </v-radio-group>
        <label  >Change Icon Types</label>
        <v-radio-group style="margin-top:15px;" row v-model="landing.advanced.global.weather_icon_type">
          <v-radio
                  style="margin-right: 15px;"
              v-for="type in weatherIconTypes"
              :label="type.label"
              :value="type.value"
              :key="type.id"
          >
          </v-radio>
        </v-radio-group>
        <div style="display: flex;align-items: center;margin-bottom:15px;">
        <label class="label" style="width: 250px; margin-right: 15px; margin-bottom: 0">Change Font Color</label>
        <v-menu
            style="margin-top: 0;"
            top
            nudge-left="16"
            nudge-bottom="98"
            :close-on-content-click="false"
            v-model="show_btn_name_color"
        >
          <template v-slot:activator="{ on }">
            <div class="colorpicker" :style="`background-color:${landing.advanced.global.weather.font_color}`" v-on="on" />
          </template>
          <v-card>
            <v-card-text class="pa-0">
              <v-color-picker v-model="landing.advanced.global.weather.font_color" @input="show_btn_name_color = false" flat/>
            </v-card-text>
          </v-card>
        </v-menu>
        </div>
        <div v-if="landing.advanced.global.weather.style !== 'lite'"
            style="display: flex; align-items: center; margin-bottom: 15px"
        >
          <label
              class="label"
              style="width: 250px; margin-right: 15px; margin-bottom: 0"
          >Border radius
            <b>{{ landing.advanced.global.weather.border_radius }}</b> px</label
          >
          <div class="slider" style="width: 80%">
            <v-slider
                hide-details
                :max="200"
                v-model="landing.advanced.global.weather.border_radius"
            ></v-slider>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
export default {
  name: "WeatherTimePage",
  data(){
    return{
      location:"",
      cities:[],
      show_btn_name_color:false,
      stylesType:[
        {id:0,value:'default',label:'Default'},
        {id:1,value:'week',label:'Week'},
        {id:2,value:'lite',label:'Lite'},
        {id:3,value:'simple',label:'Simple'}
      ],
      weatherIconTypes:[
        {id:0,value:0,label:'Default'},
        {id:1,value:1,label:'Owfont'},
      ],
      timezones:[
        {
          "offset": "GMT-12:00",
          "name": "Etc/GMT-12"
        },
        {
          "offset": "GMT-11:00",
          "name": "Etc/GMT-11"
        },
        {
          "offset": "GMT-10:00",
          "name": "America/Adak"
        },
        {
          "offset": "GMT-09:00",
          "name": "Pacific/Gambier"
        },
        {
          "offset": "GMT-08:00",
          "name": "America/Ensenada"
        },
        {
          "offset": "GMT-07:00",
          "name": "America/Denver"
        },
        {
          "offset": "GMT-06:00",
          "name": "America/Belize"
        },
        {
          "offset": "GMT-05:00",
          "name": "America/New_York"
        },
        {
          "offset": "GMT-04:30",
          "name": "America/Caracas"
        },
        {
          "offset": "GMT-04:00",
          "name": "America/La_Paz"
        },
        {
          "offset": "GMT-03:00",
          "name": "America/Sao_Paulo"
        },
        {
          "offset": "GMT-03:30",
          "name": "America/St_Johns"
        },
        {
          "offset": "GMT-02:00",
          "name": "America/Noronha"
        },
        {
          "offset": "GMT-01:00",
          "name": "Atlantic/Cape_Verde"
        },
        {
          "offset": "GMT",
          "name": "Europe/Belfast"
        },
        {
          "offset": "GMT+01:00",
          "name": "Africa/Algiers"
        },
        {
          "offset": "GMT+02:00",
          "name": "Africa/Cairo"
        },
        {
          "offset": "GMT+03:00",
          "name": "Europe/Minsk"
        },
        {
          "offset": "GMT+03:30",
          "name": "Asia/Tehran"
        },
        {
          "offset": "GMT+04:00",
          "name": "Europe/Moscow"
        },
        {
          "offset": "GMT+04:30",
          "name": "Asia/Kabul"
        },
        {
          "offset": "GMT+05:00",
          "name": "Asia/Tashkent"
        },
        {
          "offset": "GMT+05:30",
          "name": "Asia/Kolkata"
        },
        {
          "offset": "GMT+05:45",
          "name": "Asia/Katmandu"
        },
        {
          "offset": "GMT+06:00",
          "name": "Asia/Dhaka"
        },
        {
          "offset": "GMT+06:00",
          "name": "Asia/Yekaterinburg"
        },
        {
          "offset": "GMT+06:30",
          "name": "Asia/Rangoon"
        },
        {
          "offset": "GMT+07:00",
          "name": "Asia/Bangkok"
        },
        {
          "offset": "GMT+07:00",
          "name": "Asia/Novosibirsk"
        },
        {
          "offset": "GMT+08:00",
          "name": "Etc/GMT+8"
        },
        {
          "offset": "GMT+08:45",
          "name": "Australia/Eucla"
        },
        {
          "offset": "GMT+09:00",
          "name": "Asia/Irkutsk"
        },
        {
          "offset": "GMT+09:30",
          "name": "Pacific/Marquesas"
        },
        {
          "offset": "GMT+10:00",
          "name": "Asia/Yakutsk"
        },
        {
          "offset": "GMT+10:30",
          "name": "Australia/Lord_Howe"
        },
        {
          "offset": "GMT+11:00",
          "name": "Asia/Vladivostok"
        },
        {
          "offset": "GMT+11:30",
          "name": "Pacific/Norfolk"
        },
        {
          "offset": "GMT+12:00",
          "name": "Etc/GMT+12"
        },
      ],
    }
  },
  computed:{
    ...mapState(['landing'])
  },
  mounted(){
    if(this.landing.advanced.global.city_id){
      this.cities.push(this.landing.advanced.global.city_id);
    }
    this.location = this.landing.advanced.global.show_locations;
    this.changeLocation();
  },
  methods:{
    ...mapMutations('advanced',['setLocation']),
    ...mapMutations('landing',['setLandingLocation']),
    changeLocation(){
      this.setLocation(this.location);
    },
    changeLandingLocation(){
      this.setLandingLocation(this.location)
    },
    setChanges(){
      this.$store.dispatch('landing/changeCity',this.landing.advanced.global.city_id);
      this.landing.advanced.global.show_locations = this.landing.advanced.global.city_id.name;
    },
    async setCities(val){
      if(val && val.length === 3){
        try{
          const response = await this.$axios.get(`${process.env.VUE_APP_API_URL}get-city?city=`+val)
          this.cities = response.data;
        }catch (e){
          console.log(e);
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
